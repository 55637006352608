class DownloadFile {
  download(fileName, source) {
    const element = document.createElement('a');
    element.setAttribute('id', 'dlFileElement');
    element.setAttribute('href', source);
    element.setAttribute('download', fileName);

    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();

    document.body.removeChild(element);
  }

  getBase64FromImageUrl(elementID) {
    // take any image
    let img = document.getElementById(elementID);

    // make <canvas> of the same size
    let canvas = document.createElement('canvas');
    canvas.width = img.naturalWidth;
    canvas.height = img.naturalHeight;

    let context = canvas.getContext('2d');

    // copy image to it (this method allows to cut image)
    context.drawImage(img, 0, 0);
    // we can context.rotate(), and do many other things on canvas

    // toBlob is async opereation, callback is called when done
    canvas.toBlob(function(blob) {
      // blob ready, download it
      let link = document.createElement('a');
      link.download = 'example.jpg';

      link.href = URL.createObjectURL(blob);
      link.click();

      // delete the internal blob reference, to let the browser clear memory from it
      URL.revokeObjectURL(link.href);
    }, 'image/jpeg');
  }
}

export default DownloadFile;
